<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ $t("app.timesAndPrices") }}
        </h4>

        <div class="tab-danger pt-3">
          <div class="col-12 row">
            <div class="col-1"></div>
            <div class="form-group col-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 15 {{ $t("app.minutes") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_15min"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 30 {{ $t("app.minutes") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_30min"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 60 {{ $t("app.minutes") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_60min"
                v-on:input="onFormChange(false)"
              />
            </div>
          </div>

          <div><br /><br /></div>

          <!-- Nav Tabs -->
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                href="#tab-time15"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="true"
                :class="
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15 active show'
                    : ''
                "
              >
                15 {{ $t("app.minutes") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time30"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                30 {{ $t("app.minutes") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time60"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                60 {{ $t("app.minutes") }}
              </a>
            </li>
          </ul>
          <!-- End Nav Tabs -->

          <!-- Tab Panels -->
          <div class="tab-content">
            <div
              role="tabpanel"
              class="tab-pane fade pt-3"
              :class="$i18n.locale == 'ar' ? 'active show' : ''"
              id="tab-time15"
            >
              <div class="col-12">
                <p><br /></p>
                <!-- <div 
                                  v-for="(time, idx) in $props.times_15min" 
                                  class="col-2 btn mr-2 mb-2"
                                  :class="(time['status'] ? 'btn-success' : 'btn-secondary')"
                                  @click="onFormChange(idx, 'times_15min'); (time['status']) ? false : true">
                                  {{ time['value'] }} - {{ idx }}
                              </div> -->
                <div
                  v-for="(time, idx) in times_15min"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_15min')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time30"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_30min"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_30min')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time60"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_60min"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_60min')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- End Tab Panels -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormAvailableTimes",
  props: [
    "times_15min",
    "times_30min",
    "times_60min",

    "price_15min",
    "price_30min",
    "price_60min",
  ],
  data() {
    return {
      row: {
        times_15min: this.$props.times_15min,
        times_30min: this.$props.times_30min,
        times_60min: this.$props.times_60min,

        price_15min: this.$props.price_15min,
        price_30min: this.$props.price_30min,
        price_60min: this.$props.price_60min,

        selected_15min: {},
        selected_30min: {},
        selected_60min: {},
      },
    };
  },
  mounted() {},
  methods: {
    onFormChange(id, type) {
      // get status
      console.log(this.row);
      if (type == "times_15min") {
        if (this.row.times_15min[id]["status"]) {
          this.row.times_15min[id]["status"] = false;
        } else {
          this.row.times_15min[id]["status"] = true;
        }
      } else if (type == "times_30min") {
        if (this.row.times_30min[id]["status"]) {
          this.row.times_30min[id]["status"] = false;
        } else {
          this.row.times_30min[id]["status"] = true;
        }
      } else if (type == "times_60min") {
        if (this.row.times_60min[id]["status"]) {
          this.row.times_60min[id]["status"] = false;
        } else {
          this.row.times_60min[id]["status"] = true;
        }
      }

      for (let i = 0; i < this.row.times_15min.length; i++) {
        this.row.selected_15min[i] = {
          value: this.row.times_15min[i]["value"],
          status: this.row.times_15min[i]["status"],
        };
      }

      for (let x = 0; x < this.row.times_30min.length; x++) {
        this.row.selected_30min[x] = {
          value: this.row.times_30min[x]["value"],
          status: this.row.times_30min[x]["status"],
        };
      }

      for (let y = 0; y < this.row.times_60min.length; y++) {
        this.row.selected_60min[y] = {
          value: this.row.times_60min[y]["value"],
          status: this.row.times_60min[y]["status"],
        };
      }

      const data = {
        times_15min: this.row.selected_15min,
        times_30min: this.row.selected_30min,
        times_60min: this.row.selected_60min,

        price_15min: this.row.price_15min,
        price_30min: this.row.price_30min,
        price_60min: this.row.price_60min,
      };
      this.$emit("timesChange", data);
    },
  },
};
</script>
